@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.selectbtn {
  display: grid; /* Enable Grid */
  place-items: center;
  margin-top: 5px;
  margin-right: 5px;
  padding: 10px;

  background: gold;
  border: 2px solid gold;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  color: black;
  font-size: x-large;
}

.trip-des {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgb(249, 175, 0);
  height: 35px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
.trip-des-child {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.merocard {
  padding: none;
  margin-bottom: 10px;
}
.meroservicecard {
  padding: none;
  margin-bottom: 10px;
  border: 4px solid rgb(249, 175, 0);
  border-radius: 15px;
}
/* Button styles */
.tab-buttons button {
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  background-color: #ddd;
  font-size: 16px;
}
.tab-buttons .active {
  border-radius: 6px;
  background-color: rgb(249, 175, 0);
  color: black;
}
/* Content styles */
.tab-content {
  display: none;
  padding: 20px;
  border: 1px solid #ddd;
  margin-top: 10px;
}
.tab-content.active {
  display: block;
}

.service-menu {
  display: flex;

  background-color: rgb(249, 175, 0);
  width: 100%;
  border-radius: 10px;
}
.service_item {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: black;
  font-size: 20px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.06);
}
.service_item:hover {
  background-color: transparent;
  transform: 1s ease;
}

.desktop-reservation {
  display: none;
}

.cardko {
  /* background-color: #ffffff; Light gray background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
@media (max-width: 1024px) {
  .hide-on-mobile {
    display: none;
  }
}

@media (min-width: 1025px) {
  /* Adjust the size for "desktop" */
  .hide-on-desktop {
    display: none;
  }
}

.demo {
  height: 100vh;
  display: flex;
  gap: 10px;
  flex-direction: column;

  margin-top: 110px;
  padding-left: 20px;
  padding-top: 20px;
}
.price-card {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  background-color: white;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}
.price-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 250px;
  height: 100%;
}
.price-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Makes the image cover the div */
  /* display: block; */
}
.slider-box {
  height: 125px;
  margin-bottom: 20px;
}

.video-height {
  background-color: transparent;
  padding-top: 72px;
  padding-bottom: 36px;
  min-height: 100vh;
}
.add-stop-input {
  width: 100%;
}
.my-class {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 0.625rem; /* gap-2.5 in Tailwind is 0.625rem */
  padding-left: 1.25rem; /* px-5 in Tailwind is 1.25rem */
  padding-right: 1.25rem;
  position: relative;
}
.stop-class {
  width: 100%;
}
/* In your styles/globals.css */
.zoom-image {
  transition: transform 0.3s ease-in-out;
  padding: 2px;
  border-radius: 6px;
}

.zoom-image:hover {
  transform: scale(1.15);
  padding: 2px;
  border-radius: 6px;
}
.fleet-image-container {
  position: relative;
  overflow: hidden; /* Prevents image from overflowing */
  border-radius: 6px;
}

.add-stop-btn {
  margin-top: 43px;
}
@media (max-width: 558px) {
  /* Adjust the size for "desktop" */
  .add-stop-btn {
    margin-top: 50px;
  }
  .add-stop-input {
    width: 400px;
  }
}
.available-vehicle {
  display: flex;
}
.selected-vehicle-btn {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: rgb(10, 158, 17);
  border: none;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  padding: 5px 10px;

  font-family: var(--title-font);
}
.price-select-col {
  display: flex;
  width: 30%;
  flex-direction: column;
  padding: 10px;
  justify-content: space-around;
  align-items: center;
}
.kalo-bg {
  background-color: black;
}
.seto-text {
  color: white;
}

.price-tag {
  font-size: medium;
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 587px) {
  /* Adjust the size for "desktop" */
  .available-vehicle {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .selected-vehicle-btn {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background-color: rgb(10, 158, 17);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 15px;
  }
  /* .selected-vechile-circle {
    display: none;
  } */
  .price-select-col {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 588px) and (max-width: 1200px) {
  .selected-vehicle-btn {
    padding: 5px;
    font-size: 15px;
  }
  .price-select-col {
    width: 70%;
  }
}

.FormRoot2 {
  width: 100%;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.FormField2 {
  display: grid;

  margin-bottom: 1px;
}

.FormLabel {
  font-size: 15px;
  font-weight: 600;
  line-height: 32px;
  color: #fff;
}

.FormMessage {
  font-size: 12px;
  color: rgb(235, 33, 33);
  opacity: 0.8;
}

.Input2,
.Textarea2 {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 4px; */
  background: transparent;
  font-size: 15px;
  color: #fff;
  border-bottom: 1px solid #9c9c9c;

  /* box-shadow: 0 0 0 1px #000; */
}
.Input2:hover,
.Textarea2:hover {
  border-bottom: 1px solid #fff;
  background-color: transparent;
  /* box-shadow: 0 0 0 1px #000; */
}

.Input2:focus,
.Textarea2:focus {
  /* box-shadow: 0 0 0 2px #000; */
  border-bottom: 1px solid #fff;
  background: transparent;
  outline: none;
  /* box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px; */
}

.Input2::selection,
.Textarea2::selection {
  color: #000;
  background-color: #c2e209;
}

.Input2 {
  padding: 0 10px;
  height: 32px;
  line-height: 1;
}

.Textarea2 {
  resize: none;
  padding: 10px;
  text-wrap: wrap;
}

.Button4 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  padding: 12px 10px;
  /* max-width: 405px; */
  width: 100%;
  /* border: 1px solid transparent; */
  /* background: linear-gradient(180deg, #E16A6A -84.37%, #5400FF 175%); */
  /* box-shadow: 0 2px 10px var(--black-a7); */
  color: #fff;
  font-weight: 600;
  background: linear-gradient(180deg, #f9af00 90%, #f9af00 100%);
}
.Button4:hover {
  /* border: 1px solid #6083f3; */
  background-color: #f9af00;
  color: #cc9763;
}
.Button4:focus {
  box-shadow: 0 0 0 2px #f9af00;
}

/* For phone form */
.special-label {
  display: none !important;
}

.react-tel-input .form-control {
  font-size: 15px;
  background: transparent !important;
  color: #fff !important;
  border: none !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0px !important;
  width: 100% !important;
  outline: none;
  padding: 6.5px 5px 6.5px 58px !important;
  transition:
    box-shadow ease 0.25s,
    border-color ease 0.25s;
}

.react-tel-input .flag-dropdown {
  z-index: 2;
  /* background: #000; */
}

.react-tel-input .form-control:focus {
  /* border-color: #000f; */
  box-shadow: none !important;
  border-bottom: 1px solid #fff !important;
}

.section-threejs {
  position: relative;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  overflow: hidden; /* Prevent scrolling */
}
